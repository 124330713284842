import { default as _91slug_93RyVVoKkmcHMeta } from "/vercel/path0/pages/[slug].vue?macro=true";
import { default as addressED39XkEx1LMeta } from "/vercel/path0/pages/account/address.vue?macro=true";
import { default as _91id_93vsCajmochyMeta } from "/vercel/path0/pages/account/orders/[id].vue?macro=true";
import { default as _91id_93Hyh9piaRP3Meta } from "/vercel/path0/pages/account/orders/cancel/[id].vue?macro=true";
import { default as indexrVzRtN8So8Meta } from "/vercel/path0/pages/account/orders/index.vue?macro=true";
import { default as reviewkw0QvsvqszMeta } from "/vercel/path0/pages/account/review.vue?macro=true";
import { default as _91id_93LyxeWy1qatMeta } from "/vercel/path0/pages/account/statement/detail/[id].vue?macro=true";
import { default as indexN7Mk5OSDkkMeta } from "/vercel/path0/pages/account/statement/index.vue?macro=true";
import { default as userGM8NpOjO4eMeta } from "/vercel/path0/pages/account/user.vue?macro=true";
import { default as wishlistGhST4ruUO2Meta } from "/vercel/path0/pages/account/wishlist.vue?macro=true";
import { default as cart3AXNnOUus9Meta } from "/vercel/path0/pages/cart.vue?macro=true";
import { default as indexraiq4bUafSMeta } from "/vercel/path0/pages/checkout/index.vue?macro=true";
import { default as paymentuM71ATR39tMeta } from "/vercel/path0/pages/checkout/payment.vue?macro=true";
import { default as summary_46client78Jm96xHpEMeta } from "/vercel/path0/pages/checkout/summary.client.vue?macro=true";

let _createClientPage
async function createClientPage(loader) {
  _createClientPage ||= await import("/vercel/path0/node_modules/nuxt/dist/components/runtime/client-component").then(r => r.createClientPage)
  return _createClientPage(loader);
}
import { default as request2WQ1WUNndMMeta } from "/vercel/path0/pages/customer/request.vue?macro=true";
import { default as successJ41fmSWw7VMeta } from "/vercel/path0/pages/customer/success.vue?macro=true";
import { default as iletisimIx4dOB2oS1Meta } from "/vercel/path0/pages/iletisim.vue?macro=true";
import { default as indexe9Brt5DfdhMeta } from "/vercel/path0/pages/index.vue?macro=true";
import { default as logint0AWlhQgM0Meta } from "/vercel/path0/pages/login.vue?macro=true";
import { default as offergxD7Pnc9PIMeta } from "/vercel/path0/pages/offer.vue?macro=true";
import { default as statusx1IQqzuI8pMeta } from "/vercel/path0/pages/order/status.vue?macro=true";
import { default as trackAHh4kbwjG4Meta } from "/vercel/path0/pages/order/track.vue?macro=true";
import { default as forgotaWbLRONbBIMeta } from "/vercel/path0/pages/password/forgot.vue?macro=true";
import { default as resethWbKa6yAgnMeta } from "/vercel/path0/pages/password/reset.vue?macro=true";
import { default as payment_45notificationXQUaif1x8JMeta } from "/vercel/path0/pages/payment-notification.vue?macro=true";
import { default as summary0jYDC7xYJEMeta } from "/vercel/path0/pages/payment/summary.vue?macro=true";
import { default as registerIikvtlfpbHMeta } from "/vercel/path0/pages/register.vue?macro=true";
import { default as searchBIFC2dE21WMeta } from "/vercel/path0/pages/search.vue?macro=true";
import { default as locationszh8wpqUaFcMeta } from "/vercel/path0/modules/locationsPage/pages/locations.vue?macro=true";
import { default as component_45stub5X4Ei38PMgMeta } from "/vercel/path0/node_modules/nuxt/dist/pages/runtime/component-stub.js?macro=true";
import { default as component_45stub5X4Ei38PMg } from "/vercel/path0/node_modules/nuxt/dist/pages/runtime/component-stub.js";
export default [
  {
    name: "slug___tr",
    path: "/:slug()",
    meta: _91slug_93RyVVoKkmcHMeta || {},
    component: () => import("/vercel/path0/pages/[slug].vue")
  },
  {
    name: "account-address___tr",
    path: "/account/address",
    meta: addressED39XkEx1LMeta || {},
    component: () => import("/vercel/path0/pages/account/address.vue")
  },
  {
    name: "account-orders-id___tr",
    path: "/account/orders/:id()",
    meta: _91id_93vsCajmochyMeta || {},
    component: () => import("/vercel/path0/pages/account/orders/[id].vue")
  },
  {
    name: "account-orders-cancel-id___tr",
    path: "/account/orders/cancel/:id()",
    meta: _91id_93Hyh9piaRP3Meta || {},
    component: () => import("/vercel/path0/pages/account/orders/cancel/[id].vue")
  },
  {
    name: "account-orders___tr",
    path: "/account/orders",
    meta: indexrVzRtN8So8Meta || {},
    component: () => import("/vercel/path0/pages/account/orders/index.vue")
  },
  {
    name: "account-review___tr",
    path: "/account/review",
    meta: reviewkw0QvsvqszMeta || {},
    component: () => import("/vercel/path0/pages/account/review.vue")
  },
  {
    name: "account-statement-detail-id___tr",
    path: "/account/statement/detail/:id()",
    meta: _91id_93LyxeWy1qatMeta || {},
    component: () => import("/vercel/path0/pages/account/statement/detail/[id].vue")
  },
  {
    name: "account-statement___tr",
    path: "/account/statement",
    meta: indexN7Mk5OSDkkMeta || {},
    component: () => import("/vercel/path0/pages/account/statement/index.vue")
  },
  {
    name: "account-user___tr",
    path: "/account/user",
    meta: userGM8NpOjO4eMeta || {},
    component: () => import("/vercel/path0/pages/account/user.vue")
  },
  {
    name: "account-wishlist___tr",
    path: "/account/wishlist",
    meta: wishlistGhST4ruUO2Meta || {},
    component: () => import("/vercel/path0/pages/account/wishlist.vue")
  },
  {
    name: "cart___tr",
    path: "/cart",
    component: () => import("/vercel/path0/pages/cart.vue")
  },
  {
    name: "checkout___tr",
    path: "/checkout",
    meta: indexraiq4bUafSMeta || {},
    component: () => import("/vercel/path0/pages/checkout/index.vue")
  },
  {
    name: "checkout-payment___tr",
    path: "/checkout/payment",
    meta: paymentuM71ATR39tMeta || {},
    component: () => import("/vercel/path0/pages/checkout/payment.vue")
  },
  {
    name: "checkout-summary___tr",
    path: "/checkout/summary",
    component: () => createClientPage(() => import("/vercel/path0/pages/checkout/summary.client.vue"))
  },
  {
    name: "customer-request___tr",
    path: "/customer/request",
    component: () => import("/vercel/path0/pages/customer/request.vue")
  },
  {
    name: "customer-success___tr",
    path: "/customer/success",
    component: () => import("/vercel/path0/pages/customer/success.vue")
  },
  {
    name: "iletisim___tr",
    path: "/iletisim",
    component: () => import("/vercel/path0/pages/iletisim.vue")
  },
  {
    name: "index___tr",
    path: "/",
    meta: indexe9Brt5DfdhMeta || {},
    component: () => import("/vercel/path0/pages/index.vue")
  },
  {
    name: "login___tr",
    path: "/login",
    meta: logint0AWlhQgM0Meta || {},
    component: () => import("/vercel/path0/pages/login.vue")
  },
  {
    name: "offer___tr",
    path: "/offer",
    meta: offergxD7Pnc9PIMeta || {},
    component: () => import("/vercel/path0/pages/offer.vue")
  },
  {
    name: "order-status___tr",
    path: "/order/status",
    component: () => import("/vercel/path0/pages/order/status.vue")
  },
  {
    name: "order-track___tr",
    path: "/order/track",
    component: () => import("/vercel/path0/pages/order/track.vue")
  },
  {
    name: "password-forgot___tr",
    path: "/password/forgot",
    meta: forgotaWbLRONbBIMeta || {},
    component: () => import("/vercel/path0/pages/password/forgot.vue")
  },
  {
    name: "password-reset___tr",
    path: "/password/reset",
    meta: resethWbKa6yAgnMeta || {},
    component: () => import("/vercel/path0/pages/password/reset.vue")
  },
  {
    name: "payment-notification___tr",
    path: "/payment-notification",
    component: () => import("/vercel/path0/pages/payment-notification.vue")
  },
  {
    name: "payment-summary___tr",
    path: "/payment/summary",
    component: () => import("/vercel/path0/pages/payment/summary.vue")
  },
  {
    name: "register___tr",
    path: "/register",
    meta: registerIikvtlfpbHMeta || {},
    component: () => import("/vercel/path0/pages/register.vue")
  },
  {
    name: "search___tr",
    path: "/search",
    meta: searchBIFC2dE21WMeta || {},
    component: () => import("/vercel/path0/pages/search.vue")
  },
  {
    name: "contact___tr",
    path: "/magazalarimiz",
    component: () => import("/vercel/path0/modules/locationsPage/pages/locations.vue")
  },
  {
    name: component_45stub5X4Ei38PMgMeta?.name,
    path: "/https://kozmet.com/maleya-hair-stick-wax-30gr-sac-sekillendirici-sac-sabitleyici",
    component: component_45stub5X4Ei38PMg
  }
]